import React from 'react';
import { MyAppBar } from './components/surface/appBar/MyAppBar';
import { MainFeaturedPost } from './components/main/MainFeaturedPost';
import { BlogCard } from './components/surface/card/BlogCard';
import { Box, Container, CssBaseline, Typography } from '@material-ui/core';
import { Footer } from './components/footer/Footer';
import { imageText, sections, magazine1, magazine2, premios, prensa } from './feeds/ContentFeed';
import { PageContent } from './components/page/PageContent';
import { MyCarousel } from './components/dataVisualization/carousel/MyCarousel';
import { Images } from './components/dataVisualization/Images';

export const App = () => {
	return (
		<React.Fragment>
			<CssBaseline />
			<div id="index">Simplemente Diosa</div>
			<Container maxWidth="md" >
				<MyAppBar />
				<MainFeaturedPost />
				<PageContent />
				{
					sections.map( (item, idx) => (
						<div key={ idx } id={ item.id }>
							{ (item.title) && (
									<Typography component="h3" variant="h3" color="textSecondary">
										<Box fontStyle="oblique" textAlign="center" fontWeight="fontWeightLight">{ item.title }</Box>
									</Typography>
								) }
							{ item.section.map( (part, idx) => ( <BlogCard key={ idx } content={ part } /> ) ) }
						</div>
					))
				}

				<Typography id="magazine" component="h3" variant="h3" color="textSecondary">
					<Box fontStyle="oblique" textAlign="center" fontWeight="fontWeightLight">Magazine</Box>
				</Typography>
				<MyCarousel images={ magazine1 } />
				<MyCarousel images={ magazine2 } />

				<Typography id="premios" component="h3" variant="h3" color="textSecondary">
					<Box fontStyle="oblique" textAlign="center" fontWeight="fontWeightLight">Premios</Box>
				</Typography>
				<Images imagenes={ premios } grid={ 4 } />
				<Images imagenes={ prensa } grid={ 6 } />

			</Container>
			<Footer title="Simplemente Diosa" description={ imageText } />
		</React.Fragment>
	);
}
