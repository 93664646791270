import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Backdrop, Fade, Box, Typography, Grid, Card, CardActionArea, CardContent, CardMedia, Hidden, Paper } from '@material-ui/core';
import { BlogCardStyles } from './BlogCardStyles';
import { alt } from '../../../feeds/ContentFeed';
import { MyList } from '../../dataVisualization/list/MyList';

export const BlogCard = (props) => {
    const classes = BlogCardStyles();
    const { content } = props;
    const [ open, setOpen ] = useState(false);
    const handleOpen  = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div className={ classes.root }>
            <Grid container direction="row" justify="center" alignItems="center" spacing={ 2 }>
                <Grid item xs={ 12 } >
                    <CardActionArea component="a" onClick={handleOpen}>
                        <Card className={ classes.card }>
                            <div className={ classes.cardDetails }>
                                <CardContent>
                                    <Typography variant="h5"><Box fontStyle="oblique">{ content.main_title }</Box></Typography>
                                    <Typography variant="subtitle1" color="textSecondary"><Box fontStyle="oblique">{ content.main_subtitle }</Box></Typography>
                                    <Hidden smUp><img className={ classes.imageXs } src={ content.image } alt={ alt } /></Hidden>
                                    {
                                        content.main_text.map( (text, idx) => (
                                            <Typography key={ idx } variant="subtitle1"><Box fontSize={ 14 } fontWeight="fontWeightRegular">{ text }</Box></Typography>
                                        ))
                                    }
                                    <Typography variant="subtitle1" color="textSecondary" className={ classes.leerMas }>Leer más...</Typography>
                                    
                                </CardContent>
                            </div>
                            <Hidden xsDown><CardMedia image={ content.image } title={ alt } className={ classes.cardMedia }/></Hidden>
                        </Card>
                    </CardActionArea>
                    <Modal 	className={classes.modal} open={open} onClose={handleClose} closeAfterTransition
                            BackdropComponent={Backdrop} BackdropProps={{ timeout: 500, }}
                            aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
                        <Fade in={open}>
                        <Paper elevation={ 3 } className={ window.screen.width < 800 ? classes.paperMovil : classes.paper } >
                            <Typography variant="h6" color="textSecondary" id="transition-modal-title"><Box fontStyle="oblique">{ content.page_title }</Box></Typography>
                            <Grid container direction="row" className={ classes.grid }>
                                <Grid item xs={ 12 } sm={ content.page_li ? 8 : 12 } >
                                {
                                    content.page_text.map( (text, idx) => (
                                        <Typography key={ idx } variant="subtitle1" id="transition-modal-description"><Box fontSize={ 14 } fontWeight="fontWeightRegular">{ text }</Box></Typography>
                                    ))
                                }
                                </Grid>
                                <Grid item xs={ 12 } sm={ content.page_li ? 4 : false } className={ classes.image }>
                                {
                                    content.page_li && <MyList page_li={ content.page_li } />
                                }
                                </Grid>
                            </Grid>
                        </Paper>
                        </Fade>
                    </Modal>
                </Grid>
            </Grid>
        </div>
    );
}

BlogCard.propTypes = {
    content: PropTypes.object.isRequired,
};
