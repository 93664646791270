import React from 'react';
import { Grid } from '@material-ui/core';
import { ImageCard } from '../surface/card/ImageCard';


export const Images = (props) => {

    const { imagenes, grid } = props;

    return (
        <Grid container direction="row" justify="center" alignItems="center" spacing={ 2 }>
            {
                imagenes.map( (imagen, idx) => (
                    <Grid key={ idx } item xs={ 12 } sm={ idx === 0 ? 12 : grid }>
                        <ImageCard image={ imagen.image } title={ imagen.title } />
                    </Grid>
                ))
            }
            
        </Grid>
    )
}
