import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardMedia } from '@material-ui/core';
import { alt } from '../../../feeds/ContentFeed';

const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '100%',
      margin: theme.spacing(4)
  },
}));

export const ImageCard = (props) => {
    const classes = useStyles();
    const { image, title } = props;

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia component="img" alt={ alt }  image={ image } title={ title } />
            </CardActionArea>
        </Card>
    );
}