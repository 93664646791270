import React from 'react';
import { Typography, Grid, Box, Paper } from '@material-ui/core';
import { MainFeaturedPostStyles } from './MainFeaturedPostStyles';
import { imageText, alt } from '../../feeds/ContentFeed';

export const MainFeaturedPost = () => {
	const classes = MainFeaturedPostStyles();
	const url = 'url(/assets/featuredPost.jpg)';
	const src = '/assets/featuredPost.jpg';

  	return (
		<Paper className={classes.mainFeaturedPost} style={{ backgroundImage: { url } }}>
			{ <img style={{ display: 'none' }} src={ src } alt={ alt } /> }
			<div className={classes.overlay} />
			<Grid container direction="row-reverse">
				<Grid item md={6} className={ classes.item }>
					<div className={classes.mainFeaturedPostContent}>
						<Typography variant="h4" color="inherit" gutterBottom><Box fontStyle="oblique" fontWeight="fontWeightLight">{ imageText }</Box></Typography>
					</div>
				</Grid>
				
			</Grid>
		</Paper>		
  	);
};
