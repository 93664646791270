import { makeStyles, createStyles } from '@material-ui/core/styles';

export const PageContentStyles = makeStyles((theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			textAlign: 'center',
			marginTop: theme.spacing(5),
		},
		text: {
			paddingTop: theme.spacing(2),
		},
		title: {
			padding: theme.spacing(1),
		},
		grid: {
			padding: theme.spacing(2),
			color: theme.palette.text.secondary,
		},
		image: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},

	}),
);
