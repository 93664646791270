import { makeStyles, createStyles } from '@material-ui/core/styles';

export const MyAvatarStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			'& > *': {
				margin: theme.spacing(1),
			},
		},
		image: {
			alignSelf: 'stretch',
			width: '95%',
			height: '95%',
		},
	}),
);