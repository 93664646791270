import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import { MyAvatarStyles } from './MyAvatarStyles';

export const MyAvatar = (props) => {
    const classes = MyAvatarStyles();
    const { alt, src } = props;
    
    return (
      	<div className={ classes.root } >
        	<Avatar variant="rounded" alt={ alt } src={ src } className={ classes.image }/>
      	</div>
    )
};

MyAvatar.propTypes = {
  	alt: PropTypes.string.isRequired,
  	src: PropTypes.string.isRequired,
};
