export const alt = "Simplemente Diosa - Premier - Tratamientos Faciales - Tratamientos Corporales - Belleza - Bienestar - Tecnología led e infrarroja - Lifting - Stop Manchas - Masaje Relajante";

export const magazine1 = {
    slides: [
        (<img src="/assets/magazine1/slides/magazine_slides_1.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/slides/magazine_slides_2.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/slides/magazine_slides_3.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/slides/magazine_slides_4.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/slides/magazine_slides_5.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/slides/magazine_slides_6.JPG" alt={ alt } />),
    ],
    slidesMovil: [
        (<img src="/assets/magazine1/slides_movil/magazine_slides_1.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/slides_movil/magazine_slides_2.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/slides_movil/magazine_slides_3.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/slides_movil/magazine_slides_4.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/slides_movil/magazine_slides_5.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/slides_movil/magazine_slides_6.JPG" alt={ alt } />),
    ],
    thumbnails: [
        (<img src="/assets/magazine1/thumbnails/magazine_thumbnails_1.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/thumbnails/magazine_thumbnails_2.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/thumbnails/magazine_thumbnails_3.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/thumbnails/magazine_thumbnails_4.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/thumbnails/magazine_thumbnails_5.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/thumbnails/magazine_thumbnails_6.JPG" alt={ alt } />),
    ],
    thumbnailsMovil: [
        (<img src="/assets/magazine1/thumbnails_movil/magazine_thumbnails_1.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/thumbnails_movil/magazine_thumbnails_2.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/thumbnails_movil/magazine_thumbnails_3.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/thumbnails_movil/magazine_thumbnails_4.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/thumbnails_movil/magazine_thumbnails_5.JPG" alt={ alt } />),
        (<img src="/assets/magazine1/thumbnails_movil/magazine_thumbnails_6.JPG" alt={ alt } />),
    ],
};

export const magazine2 = {
    slides: [
        (<img src="/assets/magazine2/slides/magazine_slides_1.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/slides/magazine_slides_2.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/slides/magazine_slides_3.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/slides/magazine_slides_4.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/slides/magazine_slides_5.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/slides/magazine_slides_6.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/slides/magazine_slides_7.JPG" alt={ alt } />),
    ],
    slidesMovil: [
        (<img src="/assets/magazine2/slides_movil/magazine_slides_1.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/slides_movil/magazine_slides_2.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/slides_movil/magazine_slides_3.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/slides_movil/magazine_slides_4.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/slides_movil/magazine_slides_5.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/slides_movil/magazine_slides_6.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/slides_movil/magazine_slides_7.JPG" alt={ alt } />),
    ],
    thumbnails: [
        (<img src="/assets/magazine2/thumbnails/magazine_thumbnails_1.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/thumbnails/magazine_thumbnails_2.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/thumbnails/magazine_thumbnails_3.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/thumbnails/magazine_thumbnails_4.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/thumbnails/magazine_thumbnails_5.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/thumbnails/magazine_thumbnails_6.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/thumbnails/magazine_thumbnails_7.JPG" alt={ alt } />),
    ],
    thumbnailsMovil: [
        (<img src="/assets/magazine2/thumbnails_movil/magazine_thumbnails_1.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/thumbnails_movil/magazine_thumbnails_2.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/thumbnails_movil/magazine_thumbnails_3.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/thumbnails_movil/magazine_thumbnails_4.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/thumbnails_movil/magazine_thumbnails_5.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/thumbnails_movil/magazine_thumbnails_6.JPG" alt={ alt } />),
        (<img src="/assets/magazine2/thumbnails_movil/magazine_thumbnails_7.JPG" alt={ alt } />),
    ],
};

export const premios = [
    { image: "/assets/premios/1.webp",  title: "Premios", },
    { image: "/assets/premios/2.webp",  title: "Pure Beauty Awards 2018", },
    { image: "/assets/premios/3.webp",  title: "Cosmopolitan Awards 2018", },
    { image: "/assets/premios/4.webp",  title: "Pure Beauty Awards 2017", },
    { image: "/assets/premios/5.webp",  title: "Cosmopolitan Awards 2017", },
    { image: "/assets/premios/6.webp",  title: "Dermascope Winner Awards 2016", },
    { image: "/assets/premios/7.webp",  title: "Pure Beauty Awards 2016", },
    { image: "/assets/premios/8.webp",  title: "Certificate 2014-2016", },
    { image: "/assets/premios/9.webp",  title: "Pure Beauty Awards 2015", },
    { image: "/assets/premios/10.webp", title: "Bazaar Awards 2015", },
    { image: "/assets/premios/11.webp", title: "Cosmopolitan Awards 2014", },
    { image: "/assets/premios/12.webp", title: "2014 Awards", },
    { image: "/assets/premios/13.webp", title: "Cosmopolitan Awards 2013", },
    { image: "/assets/premios/14.webp", title: "Star Awards 2013", },
    { image: "/assets/premios/15.webp", title: "2007 Awards", },
    { image: "/assets/premios/16.webp", title: "2008 Awards", },
    { image: "/assets/premios/17.webp", title: "2005 Awards", },
    { image: "/assets/premios/18.webp", title: "2005 Awards", },
    { image: "/assets/premios/19.webp", title: "2004 Awards", },
]

export const prensa = [
    { image: "/assets/prensa/prensa-1024x457.webp", title: "MAGAZINE", },
    { image: "/assets/prensa/prensa-1.webp",        title: "VOGUE", },
    { image: "/assets/prensa/prensa-2.webp",        title: "ELLE", },
    { image: "/assets/prensa/prensa-3.webp",        title: "GLAMOUR", },
    { image: "/assets/prensa/prensa-4.webp",        title: "COSMOPOLITAN", },
    { image: "/assets/prensa/prensa-5.webp",        title: "BAZAAR", },
    { image: "/assets/prensa/prensa-6.webp",        title: "MARIE CLAIRE", },
    { image: "/assets/prensa/prensa-7.webp",        title: "INSIDE", },
    { image: "/assets/prensa/prensa-8.webp",        title: "LUCKY", },
    { image: "/assets/prensa/prensa-9.webp",        title: "STYLE BEAUTY", },
    { image: "/assets/prensa/prensa-10.webp",       title: "MUY PERSONAL", },
];

export const imageText = 'Simplemente Diosa, Especialistas en Medicina Estética y Cosmetología Avanzada, en nuestro centro o a domicilio.'

// SOFIA
export const sofia =
    {
        main_title: 'Quiénes somos',
        main_subtitle: 'La metamorfosis de Sofía.',
        main_text: 'Simplemente Diosa es mi proyecto personal más allá de eso es mi alma, corazón y vida. Mi pasión...',
        contact_text: "Pida cita:",
        contact_phone: "630437799",
        contact_email: "sofiasimplementediosa@gmail.com",
        imageXs: "/assets/sofia/sofiaXs.jpg",
        imageSm: "/assets/sofia/sofiaSm.jpg",
    };

// INICIO
const begin =
    {
        main_title: 'Por qué Simplemente Diosa',
        main_subtitle: 'Las figuras de Diosas son un ejemplo de la importancia del papel de la mujer en las diferentes culturas.',
        main_text: ['Las Diosas representan la diversidad y el conflicto interior de la feminidad, como seres complejos y polifacéticos, fuertes y poderosas. Deja salir todo tu potencial y recuerda que tú eres una Diosa.'],
        page_title: 'Simplemente Diosa, Especialistas en Medicina Estética y Cosmetología Avanzada. Ofrece las técnicas más vanguardistas aplicando cosmética de alta gama de la firma Premier.',
        page_text: ['Cada vez más personas recurren a la medicina estética para mejorar su imagen.',
                     'Nuestro objetivo es realzar su verdadera belleza de la manera más natural posible, sin cirugías.',
                     'Los resultados serán visibles de forma elegante, dotando a su piel de pureza y luminosidad. No sólo conseguirá un aspecto más saludable y joven, sino que se sentirá como una verdadera Diosa.',
                     'Nuestro equipo de científicos selecciona ingredientes naturales y principios activos para crear fórmulas rompedoras que transforman genuinamente su piel.',
                     'Cada ingrediente ha sido escogido por sus beneficios para su salud y belleza.',
                     'Simplemente Diosa es el espacio donde liberarse, desconectar y disfrutar cuidándose.',
                     'La experiencia es excepcional, los resultados reales.',
                     'Cada persona es un mundo, por eso elaboramos programas de bienestar totalmente personalizados.',
                     'Simplemente Diosa comprende todos los mundos.'],
        image: "/assets/porque.jpg",
    };

const centro =
    {
        main_title: 'Servicio integral de belleza y bienestar en nuestro centro con Alma',
        main_subtitle: 'Tratamientos médicos estéticos faciales y corporales',
        main_text: ['Simplemente Diosa, su templo secreto donde relajarse y rendirse a nuestros cuidados, donde los tratamientos de belleza tienen valor añadido… un lugar pensado para usted y su placer físico y equilibrio emocional.'],
        page_title: 'Simplemente Diosa, espacio de cuidados y bienestar con Alma...',
        page_text: ['Un espacio único donde encontrar mucho más que tratamientos de belleza.',
                    'Entendemos el bienestar como un proceso de sinergia entre las emociones y la belleza de cada persona.',
                    'Cuando nos encontramos en paz y armonía los resultados de los tratamientos son mágicos, efectivos y duraderos.'],
        image: "/assets/corporal/relax2.jpg",
    };

// BELLEZA Y BIENESTAR A DOMICILIO - ACTUALIZADO
const domicilio =
    {
        main_title: 'Servicio Integral de belleza y bienestar en su hogar',
        main_subtitle: 'Tratamientos faciales y corporales, asesoramiento cosmetológico.',
        main_text: ['Dermocosmética Premier: fórmulas regenerantes y rejuvenecedoras'],
        page_title: 'Simplemente Diosa le ofrece tratamientos faciales y corporales adaptados a sus necesidades, un servicio exclusivo a domicilio.',
        page_text: ['A veces no tenemos tiempo para desplazarnos al centro de belleza o prepararnos para ese evento tan importante.',
                    'Todos los servicios del centro de belleza y bienestar, desde la comodidad de su hogar.',
                    'Solo utilizamos cosmética de alta gama para nuestros tratamientos, que han sido premiados por su excelencia.',
                    'Cada fórmula ayuda a la capacidad de la piel a renovarse. Cada producto está cuidadosamente formulado.',
                    'La piel absorbe los elementos y se convierte en una fuente vital de energía, oxígeno y nutrición. La sabiduría única de la naturaleza se combina con las nuevas tecnologías para rejuvenecer su piel con ciencia y naturaleza.',
                    'Nuestra firma se enorgullece de los tratamientos efectivos y la calidad que ha sido reconocida a lo largo de los años por su innovación. Estos tratamientos han sido premiados por su capacidad, mejora la asimilación y la entrega de nutrientes en su estado activo a la dermis.',
                    'Nuestros laboratorios son pioneros en el uso de nanotecnología en la dermocosmética gracias a la que consiguen una efectividad en sus fórmulas y un nivel de penetración de sus principios activos mucho mayor.',
                    'Asesoramos sobre los tratamientos mas vanguardistas del momento, asi como el ritual de belleza mas adecuado para que continue en casa.',
                    'Experiencia sublime, libere la mente y el cuerpo en busca de sensaciones positivas.'],
        image: "/assets/cabina.jpg",
    };

// TRATAMIENTO CÉLULAS MADRE CON TECNOLOGÍA LED E INFRARROJA - ACTUALIZADO
const led =
    {
        main_title: 'Tratamiento células madre con tecnología led e infrarroja',
        main_subtitle: 'El poder de la luz led e infrarroja conquista el mundo de la belleza. Borra la memoria de la piel eliminando definitivamente las manchas.',
        main_text: ['La luz led infrarroja equilibra, tensa, regenera y soluciona las afecciones cutáneas.'],
        page_title: 'En simplemente Diosa, el poder de la luz',
        page_text: ['Las nuevas tecnologías no paran de aplicarse al mundo de la belleza.',
                    'El principio del funcionamiento de la terapia con luz LED se basa en la estimulación de las mitocondrias, estas son los orgánulos que hay dentro de todas las células de su cuerpo y que se encargan de producir energía en forma de una molécula llamada ATP. Si se estimulan las mitocondrias con luz LED infrarroja, pueden producir más ATP, que utiliza la célula para regenerarse, reparar los daños y rejuvenecer. De hecho, el envejecimiento se identifica como un descenso de la actividad de las mitocondrias.',
                    'Luz LED roja actúa como terapia de rejuvenecimiento. Los infrarrojos usan longitud de onda larga, por lo que penetran profundamente en la piel. Dicha onda activa los fibroblastos, que son los responsables de la formación del colágeno y la elastina, fibras de la matriz cutánea que ayudan al mantenimiento de nuestra piel.',
                    'Luz LED azul está indicada para eliminar el acné y los problemas inflamatorios provocados por el mismo.',
                    'La fototerapia con luz azul consigue desinfectar y desintoxicar la piel, eliminando las bacterias atrapadas debajo de la superficie de la piel, reduciendo el enrojecimiento y la inflamación. Además cura las glándulas sebáceas y minimiza los poros, revelando una tez más suave y saludable.',
                    'También proporciona proteínas, nutrientes y oxígeno para curar y mejorar la piel de forma natural.',
                    'Luz LED verde es muy eficaz para luchar contra la hiperpigmentación excesiva o manchas ya que actúa sobre los melanocitos – células que segregan la melanina controlando el exceso de producción, eliminando manchas e igualando el tono.',
                    'Luz LED amarilla ayuda a la cicatrización y acelera la regeneración de la piel, tiene un efecto tensor efecto lifting. Reduce el enrojecimiento por rosácea , estimula la circulación linfática, mejora la oxigenación y eleva el sistema inmunitario. Es perfecta para regular las pieles sensibles, aporta luminosidad y suavidad, además de tener propiedades reafirmantes y actuar sobre el sistema nervioso, siendo una gran aliada en las pieles sensibles o con cuperosis.'],
        page_li: ['Líneas finas y arrugas.', 'Estrías.', 'Hiperpigmentación.', 'Piel suelta.', 'La pérdida de colágeno y elastina.', 'Cicatrices.',
                  'Los poros grandes.', 'Osteoartritis.', 'Artritis reumatoide.', 'Dolor de las articulaciones.', 'Fibromialgia.', 'Dolores de cabeza.', 'Manguito rotador.',
                  'Fascitis plantar.', 'Desgarro menisco.', 'Degeneración del disco.', 'Codo tenista.', 'Distensión muscular.', 'Esguinces y torceduras.', 'Túnel carpal.'],
        image: "/assets/led.jpg",
    };

/********************************************************************************************************************************************************************************/
/********************************************************************************* FACE SECTION *********************************************************************************/
/********************************************************************************************************************************************************************************/

// TRATAMIENTO ANTIAGING LIFTING
const lifting =
    {
        main_title: 'Tratamiento antiaging lifting',
        main_subtitle: 'Premier ageless future new enforced formula',
        main_text: ['En este tratamiento combatimos exitosamente los diferentes signos del envejecimiento, utilizando Medicina Biológica Molecular.',
                    'Auténtico lifting sin cirugía que propone una alternativa a los tratamientos invasivos. Estimula la producción de colágeno y elastina, aumenta la circulación sanguínea haciendo llegar los nutrientes del complejo cosmético a nuestra piel.'],
        page_title: 'Simplemente Diosa bienvenida a un futuro sin edad…',
        page_text: ['Un cuidado integral que promete rejuvenecer y mejorar con resultados visibles gracias a la recuperación de nuestros tejidos.',
                    'Las líneas de expresión no son solo el único signo notable del envejecimiento. La falta de firmeza y flacidez en la piel también son claros ejemplos del paso del tiempo.',
                    'Una disminución de colágeno, factores genéticos, las expresiones faciales y el deterioro provocado por el sol, entre otras muchas causas, aceleran su aparición.',
                    'La flacidez es una de las manifestaciones más evidentes en el rostro que se acelera con el paso del tiempo.',
                    'El descolgamiento es un reflejo del estado interno de la piel que, con la edad, pierde proteínas de soporte, tales como el colágeno y la elastina, lo que provoca que la piel cuelgue.'],
        page_li: ['Ayuda a restaurar la firmeza y elasticidad.', 'Múltiples efectos lifting.', 'Renovación celular.', 'Efecto antiedad.'],
        image: "/assets/facial/lifting.jpg",
    };

// TRATAMIENTO STOP MANCHAS
const manchas =
    {
        main_title: 'Tratamiento stop manchas',
        main_subtitle: 'Premier prestige white new enforced formula',
        main_text: ['Tratamiento que equilibra y controla la producción de melanina para disminuir la pigmentación general de la piel.',
                    'Eliminando manchas en la piel o matizando la despigmentación en personas que tienen vitíligo o quemaduras cutáneas.'],
        page_title: 'Simplemente Diosa, la piel que habito',
        page_text: ['Tratar las manchas es absolutamente prioritario, porque no sólo añaden años, sino que dan un aspecto de dejadez y una piel poco sana.',
                    'Las principales causas de la aparición de manchas son hormonales, como el embarazo y el envejecimiento. También existen otras causas: la sobreexposición al sol, la mala alimentación, no desmaquillarse...',
                    'Nuestro tratamiento para eliminar manchas es una mezcla única, desarrollada por nuestro centro de investigación, que posee ocho superactivos blanqueadores para alcanzar los mejores resultados.',
                    'La luz verde que emite nuestro dispositivo médico frena la actividad de los melanocitos, por lo que la síntesis de la melanina disminuye, reduciendo así la intensidad y el tamaño de las manchas hasta ser eliminadas.'],
        page_li: ['Reducción visible de las manchas.', 'Luminosidad.', 'Antienvejecimiento.', 'Rostro más uniforme.'],
        image: "/assets/facial/spots.jpg",
    };

// TRATAMIENTO REJUVENECE TU MIRADA
const eyes =
    {
        main_title: 'Tratamiento rejuvenece tu mirada',
        main_subtitle: 'Premier prestige new enforced formula',
        main_text: ['Tratamiento que mejora la mirada con efecto lifting y reduce bolsas y ojeras, mejorando la circulación sanguínea.',
                    'Consigue eliminar toxinas e incrementar la retención de humectación en el contorno de ojos deteniendo la aparición de líneas de expresión.'],
        page_title: 'Simplemente Diosa mas de lo que jamás imaginó…',
        page_text: ['Para esta zona están triunfando nuestros tratamientos específicos altamente concentrados en principios activos.',
                    'Si la polución y los malos hábitos perjudican la piel, imagínese cómo afectan especialmente al contorno de los ojos, que es mucho más delicado.',
                    'Nuestro tratamiento contiene ingredientes activos, combinados con minerales y vitaminas, que proporcionan una apariencia y sensación suave e increíblemente homogénea e iluminada. La desinflamación y el aumento de la circulación ayudan a prevenir las bolsas y círculos oscuros alrededor de los ojos, ya que fomenta la eliminación de toxinas aumentando la retención de humectación para reducir la aparición de líneas de expresión.'],
        page_li: ['Párpado caído', 'Bolsas', 'Ojeras', 'Líneas de expresión'],
        image: "/assets/facial/eyes.jpg",
    };

// TRATAMIENTO LUMINOSIDAD Y VITAMINAS
const vitaminas =
    {
        main_title: 'Tratamiento luminosidad y vitaminas',
        main_subtitle: 'Premier perfection',
        main_text: ['Radiante con Simplemente Diosa…',
                    'Tratamiento profundo revitalizante proporciona firmeza, elasticidad, hidratación y tersura.'],
        page_title: 'Simplemente Diosa corrige y protege la piel para un rostro perfecto…',
        page_text: ['La vitamina C, también conocida como ácido ascórbico, es el antioxidante natural más eficaz que existe. Es fundamental por su papel en la prevención del envejecimiento y por su efecto protector frente a los radicales libres. Ejerce una importante acción despigmentante y ayuda a oxigenar la piel.',
                    'Además es uno de los principios activos más valorados en dermatología debido a sus múltiples beneficios. No solo tiene poderosas propiedades antioxidantes, también estimula el metabolismo cutáneo, conseguiremos unificar el tono de la piel y recuperar la luminosidad.',
                    'Recomendado para pieles cetrinas, apagadas con falta de luz y resplandor; pieles mates, deshidratadas, con falta de vitalidad y flacidez.',
                    'Totalmente personalizado con los beneficios de nuestros polivitamínicos y remineralizantes con principios activos que ayudan a restablecer la juventud de la piel y ayudan a recuperar el brillo natural del tejido dérmico.',
                    'La piel recupera de inmediato una espectacular juventud.'],
        page_li: ['Resultado revitalizante.', 'Recuperación de luminosidad.', 'Mejora el sistema de defensa antioxidante.', 'Restaura firmeza y elasticidad.'],
        image: "/assets/facial/vits.JPG",
    };

// TRATAMIENTO ACNÉ Y CICATRICES
const acne =
    {
        main_title: 'Tratamiento acné y cicatrices',
        main_subtitle: 'Premier biox ultra new enforced formula',
        main_text: ['Tratamiento que regula el exceso de grasa luciendo un cutis liso, sin imperfecciones de forma totalmente indolora y sin cirugía.',
                    'El mismo tratamiento también sirve para eliminar marcas y cicatrices mediante una renovación y regeneración celular.',
                    'Un rostro radiante desde la primera sesión.'],
        page_title: 'Simplemente Diosa … simplemente una piel perfecta…',
        page_text: ['El acné es la enfermedad inflamatoria de la piel más común en el mundo.',
                    'El acné se produce principalmente por los cambios hormonales. Éstos provocan un aumento en la producción de sebo y la secreción excesiva de grasa que junto con las células muertas de la epidermis, bloquean el folículo piloso, provocando su inflamación y la formación de espinilla, barro o grano.',
                    'Además trabajaremos las marcas y cicatrices aplicando nuestra fórmula concentrada con microorganismos, descubierta por los biólogos de nuestra firma que tras varios estudios ha sido premiada y reconocida por sus increíbles propiedades regeneradoras, esta esencia facial ayuda a acelerar la regeneración celular.'],
        page_li: ['Mejora el sistema de defensa antioxidante.', 'Tratamiento líneas expresión y desórdenes de la piel.', 'Minimiza poros.', 'Bactericida.'],
        image: "/assets/facial/acne.jpg",
    };

export const wedding =

    // TRATAMIENTO DIOSA PARA BODAS
    {
        main_title: 'Tratamiento Diosa para bodas',
        main_subtitle: 'Terapia para el alma que te hará brillar como una Diosa.',
        main_text: ['Premier quartz rose gem gemstone. Lifting metamorfosis.',
                    'Tratamiento de belleza y bienestar que combina todas las técnicas faciales y corporales.'],
        page_title: 'Tratamiento Diosa para bodas',
        page_text: ['En toda celebración especial nos importa resaltar la belleza, sentirnos cómodas y disfrutar del momento. Por eso, en Simplemente Diosa hemos diseñado este tratamiento de belleza para bodas, ya sea la novia, el padrino, la madre de la novia, o una de los invitados que podrán lucir un aspecto joven y radiante para disfrutar al máximo de ese día único y especial.',
                    'Cuando el tiempo apremia y uno quiere lucir la mejor de las pieles optamos por tratamientos de belleza poco invasivos para celebraciones y eventos.',
                    'Empleamos técnicas no invasivas que aportan luminosidad e hidratación, aumentando el colágeno y la elastina de la piel. Incluso cerca de un acontecimiento social o familiar aporta efecto flash inmediato, que nos hará lucir radiantes eliminando los signos de fatiga y estrés, levantando las facciones al realizar una técnica milenaria con resultados similares a un lifting.',
                    'Masaje corporal con aromaterapia, este masaje neurosedante sumamente relajante gracias a los aromas, nutre e hidrata la piel renueva y suaviza gracias al poder de los aceite esenciales. Piel remodelada y luminosa, el resultado final, definitivamente una piel más joven.'],
        image: "/assets/facial/wedding.jpg",
    };

/********************************************************************************************************************************************************************************/
/******************************************************************************* END FACE SECTION *******************************************************************************/
/********************************************************************************************************************************************************************************/

/********************************************************************************************************************************************************************************/
/********************************************************************************* BODY SECTION *********************************************************************************/
/********************************************************************************************************************************************************************************/

// TRATAMIENTO REAFIRMANTE CORPORAL
const body =
    {
        main_title: 'Tratamiento reafirmante corporal',
        main_subtitle: 'Luce radiante y hermosa con una bella silueta.',
        main_text: ['El tratamiento reafirmante corporal consiste en combinar aparatología y técnica manual para acceder de manera profunda, cómoda y segura a las diferentes capas del tejido y aumentar la producción de colágeno y elastina'],
        page_title: 'Silueta más firme y tonificada con Simplemente Diosa.',
        page_text: ['El tratamiento reafirmante corporal contribuye a hacer que la piel del cuerpo recupere la elasticidad y aumente su firmeza, de manera que la silueta quede más definida. Es un tratamiento específico para todas aquellas personas que, ya sea por falta de ejercicio físico, una vida sedentaria o una alimentación inadecuada hayan perdido la firmeza original de la piel.',
                    'El objetivo del tratamiento reafirmante es trabajar la piel y los tejidos para mejorar su tono y elasticidad mediante la estimulación de la formación de nuevas fibras de colágeno.',
                    'Nuestro tratamiento estrella, ya que destaca por su capacidad para mejorar la calidad de la piel tratando la flacidez y aportando hidratación y firmeza.'],
        image: "/assets/corporal/reafirmante.JPG",
    };

// MASAJE RELAJANTE SIMPLEMENTE DIOSA
const masaje =
    {
        main_title: 'Masaje relajante Simplemente Diosa',
        main_subtitle: 'Equilibrio perfecto que necesita cuerpo y mente.',
        main_text: ['El masaje relajante que ve al organismo como un núcleo de energía vital que necesita armonía y equilibrio.'],
        page_title: 'Simplemente Diosa te abre las puertas a los sentidos…',
        page_text: ['El tratamiento relajante se inicia aplicando aceite de sésamo templado en diferentes zonas del cráneo, y continúa aplicando micropresiones desde el cuero cabelludo hasta las puntas del cabello y termina realizando un masaje descontracturante en la zona de cuello y hombros.',
                    'El máximo objetivo es aumentar la producción de endorfinas en nuestro cuerpo.',
                    'En este suave y relajante masaje se trabajan todas las zonas del cuerpo con aceites esenciales, le proporcionará un estado total de paz, equilibrio y armonía.',
                    'Eliminaremos en la zona superior del cuerpo la tensión acumulada mejorando el sistema circulatorio, el estado de relajación y la sensación de bienestar.',
                    'Desaparecerá la sensación de sobrecarga y pesadez de piernas. Las piernas cargan todo el peso de nuestro cuerpo y sufren de mayor presión sanguínea al ser la zona más alejada del corazón.',
                    'Sentirás cómo se produce una relajación inmediata, que te hará desconectar y liberar las tensiones acumuladas.'],
        image: "/assets/corporal/relax.jpg",
    };

// EL MEJOR TRATAMIENTO PARA FRENAR LA CELULITIS - ACTUALIZADO
const celulitis =
    {
        main_title: 'El mejor tratamiento para frenar la celulitis',
        main_subtitle: 'En Simplemente Diosa te ayudamos a eliminar la celulitis de forma definitiva.',
        main_text: ['Dispositivo médico de modelado lipo profesional. Efecto Triple acción: circular excéntrico, presión neumática y calor infrarrojo.',
                    'Combinado con masaje manual con aceites drenantes, eliminando grasa y líquido y generando una piel firme y elástica.'],
        page_title: 'Por fin podrás disfrutar de tu cuerpo Diosa.',
        page_text: ['Sistema de modelado lipo profesional de alta calidad, combate eficazmente la celulitis y flacidez.',
                    'Triple acción; masaje circular excéntrico , presión neumática y calor infrarrojo.',
                    'Tecnica profesional masaje subcutáneo que aumenta el flujo sanguíneo , ayudando a eliminar toxinas y reduciendo la grasa acumulada.',
                    'Esculpe la figura, alisa la piel, tonifica y reduce volumen.',
                    'Es el momento de activar nuestro organismo para evitar la acumulación de grasas y liquidos.',
                    'Encuentre tiempo para dedicárselo a si misma y poder mejorar su figura trabajando esas zonas donde la grasa se acumula con facilidad, no hay mejor aliado que un profesional que trabaje la zona y ayude a reducir la temida celulitis.'],
        image: "/assets/corporal/celulitis.jpg",
    };

/********************************************************************************************************************************************************************************/
/******************************************************************************* END BODY SECTION *******************************************************************************/
/********************************************************************************************************************************************************************************/

export const gift =

    // TARJETA REGALO EXPERIENCIA BELLEZA Y BIENESTAR - ACTUALIZADO
    {
        main_title: 'Regala Simplemente Diosa',
        main_subtitle: 'La tarjeta regalo de Simplemente Diosa le ofrece la posibilidad de regalar una experiencia inolvidable.',
        main_text: ['El regalo es una experiencia única, uno de nuestros especialistas concertará una cita en persona para entregarle la tarjeta y decidir el diagnóstico de belleza acorde a sus necesidades.'],
        page_title: 'Simplemente Diosa le regala bienestar, es su momento…. el sitio de su recreo',
        page_text: ['Con la tarjeta regalo belleza y bienestar Simplemente Diosa podrá disfrutar de todos nuestros tratamientos.',
                    'Regala belleza y bienestar… el regalo perfecto para cualquier ocasión, para amigos, familiares o compañeros. Ofrece a quien lo recibe el lujo de elegir su propio tratamiento.'],
        image: "/assets/gift_card.jpg",
    };

export const sections = [
    {
        id: "",
        title: "",
        section: [ begin, centro, domicilio, led ]
    },
    {
        id: "tratamientos-faciales",
        title: "Tratamientos faciales",
        section: [ lifting, manchas, eyes, vitaminas, acne, wedding ]
    },
    {
        id: "tratamientos-corporales",
        title: "Tratamientos corporales",
        section: [ body, celulitis, masaje ]
    },
    {
        id: "tarjeta-regalo",
        title: "Tarjeta regalo belleza y bienestar",
        section: [ gift ]
    },
];