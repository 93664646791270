import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { MyCarouselStyles } from './MyCarouselStyles';

export const MyCarousel = (props) => {
    
    const classes = MyCarouselStyles();
    const { images } = props;
    const { slides, slidesMovil, thumbnails, thumbnailsMovil } = images;
    const [ value, setValue ] = useState(0);
    const onChange = (value) => setValue(value);

    return (
        <div className={ classes.container }>
        <Carousel   className={ classes.carousel } slidesPerPage={ 1 } clickToChange infinite centered keepDirectionWhenDragging
                    value={ value } slides={ window.screen.width < 800 ? slidesMovil : slides } onChange={ onChange } />
        <Dots number={ thumbnails.length } thumbnails={ window.screen.width < 800 ? thumbnailsMovil : thumbnails } value={ value } onChange={ onChange } />
        </div>
    )
};

MyCarousel.propTypes = {
    images: PropTypes.object.isRequired,
};
