import React from 'react';
import PropTypes from 'prop-types';
import { Container, Typography, Link, Grid } from '@material-ui/core';
import { FooterStyles } from './FooterStyles';
import { ImageCard } from '../surface/card/ImageCard';

const Copyright = () => {
	return (
		<Typography variant="body2" color="textSecondary" align="center">{ 'Copyright © ' }
			<Link color="inherit" href="http://simplementediosa.es/">simplementediosa</Link>{' '}{ new Date().getFullYear() }{ '.' }
		</Typography>
	);
}

export const Footer = (props) => {
	const classes = FooterStyles();
	const { description, title } = props;

	return (
		<footer className={ classes.footer }>
			<Container maxWidth="lg">
				<Grid container direction="row" justify="center" alignItems="center">
					<Grid item xs={ 12 } md={ 4 } >
						<ImageCard image="/assets/seguridad-covid.jpg" title="" />
					</Grid>
					<Grid item xs={ 12 } md={ 4 } >
						<Typography variant="h6" align="center" gutterBottom>{ title }</Typography>
						<Typography variant="subtitle1" align="center" color="textSecondary" component="p">{ description }</Typography>
						<Copyright />
					</Grid>
					<Grid item xs={ 12 } md={ 4 } >
						<ImageCard image="/assets/medio-ambiente.png" title="" />
					</Grid>
				</Grid>
				
			</Container>
		</footer>
	);
}

Footer.propTypes = {
  	description: PropTypes.string,
  	title: PropTypes.string,
};
