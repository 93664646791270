import React from 'react';
import { Box, Grid, Hidden, Link, Typography } from '@material-ui/core';
import { PageContentStyles } from './PageContentStyles';
import { MyAvatar } from '../dataVisualization/avatar/MyAvatar';
import { alt, sofia } from '../../feeds/ContentFeed';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export const PageContent = () => {
    const classes = PageContentStyles();
    const phone = "tel:".concat('', sofia.contact_phone);
    const email = "mailto:".concat('', sofia.contact_email);

    return (
        <div id="quienes-somos" className={ classes.root }>
        <Typography variant="h3" color="textSecondary" className={ classes.title }><Box fontWeight="fontWeightLight" fontStyle="oblique">{ sofia.main_title }</Box></Typography>
        <Typography variant="h4" color="textSecondary" className={ classes.title }><Box fontWeight="fontWeightLight" fontStyle="oblique">{ sofia.main_subtitle }</Box></Typography>
            <Grid container direction="row-reverse" className={ classes.grid }>
                <Grid item xs={ 12 } sm={ 6 }>
                    <Typography variant="subtitle1" className={ classes.text }>
                        <Box fontSize={ 16 } fontWeight="fontWeightRegular" fontStyle="oblique">{ sofia.main_text }</Box>
                    </Typography>
                    <Typography variant="subtitle1" className={ classes.title }>
                        <Box fontSize={ 28 } fontWeight="fontWeightRegular" fontStyle="oblique">{ sofia.contact_text }</Box>
                    </Typography>
                    <Typography variant="subtitle1" className={ classes.title }>
                        <Link href={ phone } color="textSecondary" underline="none">
                            <Box fontSize={ 28 } fontWeight="fontWeightRegular" fontStyle="oblique">
                                <ContactPhoneIcon fontSize="small" />
                                <WhatsAppIcon fontSize="small" />
                                { sofia.contact_phone }
                            </Box>
                        </Link>
                    </Typography>
                    <Typography variant="subtitle1" className={ classes.title }>
                        <Link href={ email } color="textSecondary" underline="none">
                            <Box fontSize={ 28 } fontWeight="fontWeightRegular" fontStyle="oblique">
                                <MailOutlineIcon fontSize="small" />
                                Enviar email
                            </Box>
                        </Link>
                    </Typography>
                </Grid>
                <Hidden smUp><Grid item xs={ 12 } sm={ 6 } className={ classes.image }><MyAvatar alt={ alt } src={ sofia.imageXs } /></Grid></Hidden>
                <Hidden xsDown><Grid item xs={ 12 } sm={ 6 } className={ classes.image }><MyAvatar alt={ alt } src={ sofia.imageSm } /></Grid></Hidden>
            </Grid>
        </div>
    )
};
