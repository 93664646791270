import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore, StarBorder } from '@material-ui/icons';
import { MyListStyles } from './MyListStyles';

export const MyList = ( { page_li } ) => {
	const classes = MyListStyles();
	const [ open, setOpen ] = useState(false);
	const handleClick = () => setOpen(!open);

	return (
		<List dense component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
			<ListItem button onClick={handleClick}>
				<ListItemText primary="Beneficios:" />
				{ open ? <ExpandLess /> : <ExpandMore /> }
			</ListItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List dense component="ul" disablePadding>
				{
					page_li.map((li, idx) => (
						<ListItem button key={ idx } className={classes.nested}>
							<ListItemIcon><StarBorder /></ListItemIcon>
							<ListItemText primary={ li } />
						</ListItem>
					))
				}
				</List>
			</Collapse>
		</List>
	);
};
