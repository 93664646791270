import { makeStyles } from '@material-ui/core/styles';

export const BlogCardStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		textAlign: 'center',
	},
    card: {
		display: 'flex',
    },
    cardDetails: {
      	flex: 1,
	},
	cardMedia: {
		width: '50%',
	},
	imageXs: {
		maxWidth: '100%',
		padding: theme.spacing(2),
	},
	leerMas: {
		marginTop: theme.spacing(2),
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: 5,
		boxShadow: theme.shadows[5],
		margin: theme.spacing(4),
		padding: theme.spacing(3, 4),
		maxWidth: 768,
		maxHeight: '90%',
		overflowY: 'auto',
    },
    paperMovil: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: 5,
		boxShadow: theme.shadows[5],
		margin: theme.spacing(4),
		maxWidth: 1024,
		padding: theme.spacing(3, 4),
		width: '100%',
		height: '90%',
		overflowY: 'auto',
    },
}));